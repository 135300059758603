import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import { ReportsTacosDynamicRequest } from '@/types/request/reports-request.types';
import { DateTime } from 'luxon';
import {
  DATE_MODE_WEEKLY,
  DATEPICKER_FORMAT,
  FILTER_MONTH_YEAR_FORMAT,
  getPrevMonthsDateRange,
  MONTH_FORMAT
} from '@/helpers/date.helper';
import BarChart from '@/components/ui/charts/bar-chart/barChart.vue';
import { TacosDynamicType } from '@/types/api/reports/tacos-dynamic.type';
import { ChartData, ChartOptions } from 'chart.js';
import { COLOR_WHITE } from '@/constants/color.constant';
import DatepickerSingle from '@/components/ui/inputs/datepicker-single/datepickerSingle.vue';
import { ProductsGetRequest } from '@/types/request/products-request.types';
import { ProductType } from '@/types/api/products/product.type';
import { BrandType } from '@/types/api/brands/brand.type';
import { BFormRadioGroup } from 'bootstrap-vue';
import MultiselectInput from '@/components/ui/inputs/multiselect-input/multiselect-input.vue';
import { getRandomColor } from '@/helpers/color.helper';
import AutocompleteInput from '@/components/ui/inputs/autocomplete-input/autocompleteInput.vue';

const title = 'tacosDynamicReport.title';

@Component({
  name: 'TacosDynamicReport',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    BoxHeader,
    BarChart: BarChart as any,
    DatepickerSingle,
    BFormRadioGroup,
    MultiselectInput,
    AutocompleteInput,
  },
})
export default class TacosDynamicReport extends Mixins(FilterMixin) {
  title = title;
  filter: any = this.initFilter();
  pageFilter: any = {
    brandId: this.$route.query.brandId || null
  }
  productsFilter: Array<{
    id: number,
    name: string
  }> = [];
  useBrandFilter = true;

  @Action('getTacosDynamicReport', { namespace: 'reports' })
  getTacosDynamicReport!: (params?: ReportsTacosDynamicRequest) => Promise<TacosDynamicType[]>;

  @Getter('getTacosDynamicReport', { namespace: 'reports' })
  tacosDynamicReport!: TacosDynamicType[];

  @Mutation('SET_TACOS_DYNAMIC_REPORT', { namespace: 'reports' })
  setTacosDynamicReport!: (params: TacosDynamicType[]) => void;

  @Action('getProducts', { namespace: 'products' })
  getProducts!: (params?: ProductsGetRequest) => Promise<ProductType[]>;

  @Getter('getProducts', { namespace: 'products' })
  products!: ProductType[];

  @Getter('getBrands', { namespace: 'brands' })
  brands!: BrandType[];

  onSelectProduct(value: ProductType) {
    this.filter.products = this.filter.products
      ? [...this.filter.products, value.id.toString()]
      : [value.id.toString()];
    this.applyFilter();
  }

  onRemoveProduct(value: ProductType) {
    this.filter.products = this.filter.products.filter(item => item !== value.id.toString());
    this.applyFilter();
  }

  created() {
    this.filter.from = this.$route.query.from || this.startMonthDate;
    this.filter.to = this.$route.query.to || this.endMonthDate;

    if (this.pageFilter.brandId) {
      this.getProductsRequest(this.pageFilter.brandId);
    }
    if (this.filter.from && this.filter.to && this.filter.products.length) {
      this.getTacosDynamicReportRequest();
    }
  }

  beforeDestroy() {
    this.setTacosDynamicReport([]);
  }

  get startMonthDate() {
    return DateTime.local().setZone('utc').startOf('month').toFormat(DATEPICKER_FORMAT);
  }

  get endMonthDate() {
    return DateTime.local().endOf('month').toFormat(DATEPICKER_FORMAT);
  }

  getProductsRequest(value) {
    this.getProducts({ brandId: value }).then(response => {
      this.productsFilter = this.getProductsOptions(response)
        .filter(item => (this.$route.query.products || []).includes(item.id.toString()))
    });
  }

  getTacosDynamicReportRequest() {
    return this.getTacosDynamicReport(this.preparedFilter);
  }

  initFilter() {
    return {
      from: this.$route.query.from || null,
      to: this.$route.query.to || null,
      type: DATE_MODE_WEEKLY,
      products: this.$route.query.products || [],
    };
  }

  get preparedFilter() {
    return {
      ...this.filter,
      from: DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(FILTER_MONTH_YEAR_FORMAT),
      to: DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(FILTER_MONTH_YEAR_FORMAT),
    };
  }

  get monthFormat() {
    return MONTH_FORMAT;
  }

  getRanges() {
    const ranges = {};

    for (let i = 0; i < 12; i++) {
      const month = DateTime.local().minus({ months: i });
      const year = month.get('year');
      const label = year !== DateTime.local().get('year') ? `${month.monthLong} ${year}` : month.monthLong;

      ranges[label] = getPrevMonthsDateRange(i, true);
    }

    return ranges;
  }

  get brandOptions() {
    return [
      { text: '---', value: null },
      ...this.brands.map(item => ({ text: item.displayName, value: item.id }))
    ];
  }

  getProductsOptions(products: ProductType[]) {
    return products.map(item => ({
      id: item.id,
      name: `${item.asin || ''} - ${item.displayName || item.name || ''}`
    }));
  }

  get productOptions() {
    return this.getProductsOptions(this.products);
  }

  onSelectBrand(value) {
    this.getProductsRequest(value);
  }

  get chartData(): ChartData {
    return {
      labels: Object.keys(this.tacosDynamicReport[0].statistics).map(item => item),
      datasets: this.tacosDynamicReport.map(item => {
        const color = getRandomColor();
        return {
          label: item.name || item.asin,
          type: 'bar',
          data: Object.values(item.statistics).map((item: any) => item.tacos ? (item.tacos * 100).toFixed(0) : 0) as any,
          borderColor: color,
          backgroundColor: color,
          pointBackgroundColor: COLOR_WHITE,
          pointBorderColor: color,
          pointBorderWidth: 2,
        }
      })
    };
  }

  get chartOptions(): ChartOptions {
    return {
      layout: {
        padding: {
          left: 50,
          right: 50,
          top: 25,
          bottom: 25,
        }
      },
      plugins: {
        tooltip: {
          enabled: false
        },
      },
      animation: {
        onProgress: ({ chart }) => {
          const ctx = chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.textBaseline = 'bottom';

          this.chartData.datasets?.forEach((item, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              meta.data.forEach((bar, index) => {
                ctx.fillText(
                  item.data?.[index] ? `${item.data?.[index]}%` : '',
                  bar.x,
                  bar.y - 5
                );
              });
            }
          })
        }
      }
    }
  }
}
